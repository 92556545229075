.ElixirLotteryPlayers {
    display: flex;
    flex-direction: column;
    gap: 20px;

    height: 100%;
    overflow: hidden;
}

.ElixirLotteryPlayers-controls {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 20px;

    font-size: large;
	font-weight: 700;
}

.ElixirLotteryPlayers-players {
    display: flex;
    flex-direction: column;
    gap: 20px;

    overflow-y: auto;
}


.ElixirLotteryPlayerField {
    display: flex;
    flex-direction: row;
}

.ElixirLotteryPlayerField-toggle {
    flex: 0 0 28px;
    height: 50px;
    cursor: pointer;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.ElixirLotteryPlayerField-toggle-enabled {
    background-color: var(--color-green);
}

.ElixirLotteryPlayerField-toggle-disabled {
    background-color: var(--color-gray);
}

.ElixirLotteryPlayerField-name {
    flex: 1 1 0;
    box-sizing: border-box;
    height: 50px;
    background-color: var(--color-white);
    color: var(--color-black);
    border-width: 0;
    border-bottom-right-radius: 8px;
    font-size: large;
    padding: 0 10px;
    outline: none;
}

.ElixirLotteryPlayerField-name:disabled {
    filter: brightness(0.4);
    -webkit-filter: brightness(0.4);
}

.ElixirLotteryPlayerField-elixirs {
    flex: 2 1 0;

    display: flex;
    flex-direction: column;
    gap: 5px;
    box-sizing: border-box;
    border-top: 1px solid var(--color-white);
    border-right: 1px solid var(--color-white);
    overflow: hidden;
}

.ElixirLotteryPlayerField-elixirs-disabled {
    border-top: 0;
    border-right: 0;
}

.ElixirLotteryPlayerField-elixirs-entry {
    flex: 1 1 0;

    box-sizing: border-box;
    height: 40px;
    background-color: var(--color-white);
    color: var(--color-black);
    font-size: medium;
    padding: 0 10px;
}
