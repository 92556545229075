.Button {
    display: inline-block;
    border-style: none;
    text-align: center;
    text-decoration: none;
    overflow: hidden;
    cursor: pointer;
    background-color: var(--color-white);
    border-radius: 5px;
    padding: 10px 16px;
    font-size: inherit;
    font-weight: inherit;
}

.Button:enabled:hover {
	filter: brightness(0.8);
}

.Button:enabled:active {
	filter: brightness(0.6);
}

.Button:disabled {
	filter: brightness(0.4);
	cursor: default;
}

.TextButton-text {
    display: block;
    white-space: inherit;
    overflow: inherit;
    text-overflow: ellipsis;
    font-size: inherit;
    font-weight: inherit;
	color: var(--color-black);
}
