.ElixirLotteryPage {
    padding: 50px;
}

.ElixirLottery {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5vw;

    height: 100%;
    padding: 0 5vw;
    white-space: nowrap;
}

.ElixirLottery-players,
.ElixirLottery-elixirs {
    flex: 1 1 0;
    height: 100%;
}
