.AtoraxxionPage {
    padding: 50px;
    overflow: hidden;
}

.AtoraxxionPageNavigationBar {
    position: fixed;
    left: 10%;
    right: 10%;
    bottom: 0;
    z-index: 10;
}

.AtoraxxionPageNavigationBar-entries {
    max-height: 50px;
    overflow-y: hidden;
    transition: 0.4s;
    -webkit-transition: 0.4s;

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.AtoraxxionPageNavigationBar-entries:hover {
    max-height: 90vh;
}

.AtoraxxionPageNavigationEntry {
    flex: 1 1 0;
    min-width: 0;

    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
	box-sizing: border-box;
    text-decoration: none;
    color: var(--color-black);
    padding: 50px 20px 30px;

    filter: brightness(0.6);
}

.AtoraxxionPageNavigationEntry:hover {
    filter: brightness(1);
}

.AtoraxxionPageNavigationEntry-active {
    filter: brightness(0.9);
}

.AtoraxxionPageNavigationEntry-active::after {
    position: absolute;
    top: 0;
    left: 10%;
    right: 10%;
    border: 16px solid transparent;
    border-top-color: var(--color-green);
    content: "";
}

.AtoraxxionPageNavigationEntry-vahmalkea {
    background-color: var(--color-vahmalkea);
}

.AtoraxxionPageNavigationEntry-sycrakea {
    background-color: var(--color-sycrakea);
}

.AtoraxxionPageNavigationEntry-yolunakea {
    background-color: var(--color-yolunakea);
}

.AtoraxxionPageNavigationEntry-orzekea {
    background-color: var(--color-orzekea);
}

.AtoraxxionPageNavigationEntry-elixirLottery {
    background-color: var(--color-white);
}

.AtoraxxionPageNavigationEntry-disabled {
    background-color: var(--color-gray);
    color: var(--color-dark-gray);
    filter: brightness(0.4);
    pointer-events: none;
}

.AtoraxxionPageNavigationEntry-symbol {
    aspect-ratio: 1 / 1;
    width: 100%;
}

.AtoraxxionPageNavigationEntry-text {
    font-size: 32px;
    font-weight: 800;
}

.AtoraxxionPage-content {
    height: 97%;
}
