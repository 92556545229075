.SycrakeaBossToolColors {
    flex: 1 1 0;
    height: 100%;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    height: 100%;
    width: 90%;
    margin: auto;
}

.SycrakeaBossToolColors-controls {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-around;
    margin: 3vh 3vw 0vh 3vw;
}

.SycrakeaBossToolColors-controls-button {
    border-radius: 2vmax;
    padding: 2vmin 3vmin;
    font-size: 4vmin;
    font-weight: bold;
    color: var(--color-black);
    background-color: var(--color-white);
}

.SycrakeaBossToolColorsColorCounter {
    border-radius: 5vmax;
    padding: 0.7vh 0vw;
    margin: 1vh 1vw;
}

.SycrakeaBossToolColorsColorCounter-red {
    background-color: var(--color-red);
}

.SycrakeaBossToolColorsColorCounter-yellow {
    background-color: var(--color-yellow);
}

.SycrakeaBossToolColorsColorCounter-white {
    background-color: var(--color-white);
}

.SycrakeaBossToolColorsColorCounter-blue {
    background-color: var(--color-blue);
}

.SycrakeaBossToolColorsColorCounter-value {
    display: inline-block;
    width: 1.3em;
    font-size: 12vh;
    color: var(--color-light-gray);
    background-color: var(--color-background);
}

.SycrakeaBossToolColorsColorCounter-value-highlight {
    animation: SycrakeaBossToolColorsColorCounter-highlight-animation 1s infinite;
    -webkit-animation: SycrakeaBossToolColorsColorCounter-highlight-animation 1s infinite;
}

@keyframes SycrakeaBossToolColorsColorCounter-highlight-animation {
    0% {
        color: var(--color-gold1);
    }
    50% {
        color: var(--color-gold2);
    }
    100% {
        color: var(--color-gold1);
    }
}

@-webkit-keyframes SycrakeaBossToolColorsColorCounter-highlight-animation {
    0% {
        color: var(--color-gold1);
    }
    50% {
        color: var(--color-gold2);
    }
    100% {
        color: var(--color-gold1);
    }
}
