.ElixirLotteryElixirs {
    display: flex;
    flex-direction: row;
    gap: 20px;

    height: 100%;
    overflow: hidden;
}

.ElixirLotteryElixirs-elixirPool,
.ElixirLotteryElixirs-elixirReserve {
    flex: 1 1 0;

    display: flex;
    flex-direction: column;
    gap: 20px;

    overflow: hidden;
}

.ElixirLotteryElixirs-elixirPool-controls,
.ElixirLotteryElixirs-elixirReserve-controls {
    display: flex;
    flex-direction: row;
    gap: 20px;

    height: 40px;
    font-size: large;
	font-weight: 700;
}

.ElixirLotteryElixirs-elixirPool-controls {
    justify-content: space-between;
}

.ElixirLotteryElixirs-elixirReserve-controls {
    justify-content: flex-end;
}

.ElixirLotteryElixirs-elixirReserve-controls-elixirType {
    border: 2px solid var(--color-white);
    width: 40px;
}

.ElixirLotteryElixirs-elixirReserve-controls-elixirType-green {
    background-color: var(--color-elixir-green);
}

.ElixirLotteryElixirs-elixirReserve-controls-elixirType-blue {
    background-color: var(--color-elixir-blue);
}

.ElixirLotteryElixirs-elixirPool-list,
.ElixirLotteryElixirs-elixirReserve-list {
    flex: 1 1 0;

    display: flex;
    flex-direction: column;
    gap: 5px;

    overflow-y: auto;
}

.ElixirLotteryElixirs-elixirReserve-list {
    filter: brightness(0.6);
}

.ElixirLotteryElixirsElixirEditingField,
.ElixirLotteryElixirsElixirField {
    display: flex;
    flex-direction: row;
    align-items: center;

    border-radius: 8px;
    padding: 5px 10px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ElixirLotteryElixirsElixirEditingField-green,
.ElixirLotteryElixirsElixirField-green {
    color: var(--color-elixir-green);
    background-color: var(--color-elixir-green-background);
}

.ElixirLotteryElixirsElixirEditingField-blue,
.ElixirLotteryElixirsElixirField-blue {
    color: var(--color-elixir-blue);
    background-color: var(--color-elixir-blue-background);
}

.ElixirLotteryElixirsElixirEditingField-icon,
.ElixirLotteryElixirsElixirField-icon {
    flex: 0 1 auto;

    height: 35px;
    width: 35px;
}

.ElixirLotteryElixirsElixirEditingField-name,
.ElixirLotteryElixirsElixirField-name {
    flex: 1 1 0;

    padding: 0 10px;
    font-size: large;
    font-weight: 600;

    overflow: inherit;
    text-overflow: inherit;
}

.ElixirLotteryElixirsElixirEditingField-arrow {
    cursor: pointer;
    user-select: none;
}

.ElixirLotteryElixirsElixirEditingField-arrow-red {
    color: var(--color-red);
}

.ElixirLotteryElixirsElixirEditingField-arrow-green {
    color: var(--color-green);
}
