.SycrakeaBossToolCountdown {
    flex: 1 1 0;
    height: 100%;

    display: grid;
    grid-template-rows: auto 1fr;
    justify-items: center;
    text-align: center;
    height: 100%;
    width: 90%;
    margin: auto;
}

.SycrakeaBossToolCountdown-controls {
    grid-row: 2 / 3;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1vw;
    grid-template-rows: 1fr 4fr 2fr;
    row-gap: 1vh;
    width: 80%;
    padding: 1vh 1vw;
}

.SycrakeaBossToolCountdown-controls-button {
    border-radius: 1vmax;
    font-size: 3vmin;
    color: var(--color-black);
    background-color: var(--color-white);
}

.SycrakeaBossToolCountdown-controls-button-decrement {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    background-color: var(--color-red) !important;
}

.SycrakeaBossToolCountdown-controls-button-increment {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    background-color: var(--color-green) !important;
}

.SycrakeaBossToolCountdown-controls-button-reset {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
}

.SycrakeaBossToolCountdown-controls-button-restart {
    grid-column: 2 / 3;
    grid-row: 2 / 4;
}

.SycrakeaBossToolCountdown-controls-button-autorestart {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
    border: 1vmin solid transparent;
}

.SycrakeaBossToolCountdown-controls-button-autorestart-active {
    animation: countdown-toggle-on-animation 2s infinite !important;
    -webkit-animation: countdown-toggle-on-animation 2s infinite !important;
}

@keyframes countdown-toggle-on-animation {
  0% {
    border-color: var(--color-flashing-green1);
  }
  50% {
    border-color: var(--color-flashing-green2);
  }
  100% {
    border-color: var(--color-flashing-green1);
  }
}

@-webkit-keyframes countdown-toggle-on-animation {
  0% {
    border-color: var(--color-flashing-green1);
  }
  50% {
    border-color: var(--color-flashing-green2);
  }
  100% {
    border-color: var(--color-flashing-green1);
  }
}

.SycrakeaBossToolCountdownDisplay {
    grid-row: 1 / 2;
    color: var(--color-white);
    font-family: 'Courier New', monospace;
    font-weight: bold;
    font-size: 30vmin;
    margin-bottom: -0.2em;
}

.SycrakeaBossToolCountdownDisplay-alert {
    color: var(--color-red);
}
