:root {
	--color-background: #1c1b22;

	--color-white: #ffffff;
	--color-black: #000000;
	--color-green: #5bff55;

	--color-gray: #979797;
	--color-dark-gray: #5f5f5f;
	--color-light-gray: #cfcfcf;
	--color-red: #f04d68;
	--color-blue: #4b9dfa;
	--color-yellow: #fffa61;

	--color-gold1: #ffe100;
	--color-gold2: #edbb05;

	--color-vahmalkea: #916b3d;
	--color-sycrakea: #527995;
	--color-yolunakea: #849932;
	--color-orzekea: #91198c;

	--color-elixir-green: #B5E45B;
	--color-elixir-green-background: #1F2617;
	--color-elixir-blue: #63B6FC;
	--color-elixir-blue-background: #18232B;

	--color-flashing-green1: #64e88b;
	--color-flashing-green2: #00f715;
}

html, body, #root {
	font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	margin: 0;
	height: 100%;
	color: var(--color-white);
	background-color: var(--color-background);
	color-scheme: light dark;
}

#root>div {
	display: block;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
